<template>
  <b-pagination-nav v-if="show && pNext.length > 1"
      v-model="pCurr" :pages="pages" limit="5" first-number last-number
      @input="changePage" class="stripmargin" :size="isXs?`sm`:`md`" />
</template>

<script>
export default {
  data() {
    return {
      pCurr: 1,
      pages: [''], // empty placeholder
      pNext: [''], // always one shorter than pages
    }
  },
  computed: {
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  methods: {
    reset() {
      this.pCurr = 1;
      this.pages = [''];
      this.pNext = [''];
    },
    update(next) {
      if (next === '' || next === null) { // nothing more
        if (this.pCurr === this.pages.length) {
          // do nothing
        } else {
          // remove the array entries/links from the pagination, as well as corresponding next elements
          this.pages.splice(this.pCurr);
          this.pNext.splice(this.pCurr);
        }
      } else {
        if (this.pCurr === this.pages.length) { // more
          this.pages.push('');
          this.pNext.push(next);
        } else {
          // update only next, others afterwards not computable: 
          //  safer would be to delete afterwards but "strange" user experience
          this.pNext[this.pCurr] = next;
        }
      }
    },
    hasMore() {
      return (this.pNext.length > 0);
    },
    changePage(pageNum) {
      if (pageNum === 1) {
        this.$emit('click', null);
      } else {
        this.$emit('click', this.pNext[pageNum-1]);
      }
    },
    getNextFromPrev(offset) {
      if (this.pNext.length < offset) {
        return null;
      }
      return this.pNext[this.pCurr-offset];
    },
    movePage(num) {
      if (this.pCurr + num > this.pages.length) {
        this.reset();
      } else {
        this.pCurr += num;
      }
    },
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
  /* .hideend >>> ul.pagination li:last-child { display: none; }
  .fixcorner >>> .page-item:nth-last-child(2) .page-link { border-top-right-radius:0.25rem; border-bottom-right-radius:.25rem; } */
  .stripmargin >>> ul.pagination { margin-bottom: 0px; }
</style>