<template>
  <div>
    <b-table striped hover sort-icon-right :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" no-local-sorting @sort-changed="sortChanged"
        v-if="items.length">
      <template #cell(type)="data">
        <span v-b-tooltip="{trigger: istouch()?'click':'hover', delay: 10}" :title="$t('tra.i'+(data.value===`CLEARING`?'clr':(data.value===`INVOICE`?'inv':'cre')))">
          <component :is="data.value===`CLEARING`?'BIconCardList':'BIconReceipt'" />
        </span>
      </template>
      <template #cell(text)="data">
        <span>{{ data.item }}</span>
      </template>
      <template #cell(amount)="data">
        <span>{{ getMoney(data.item.amount, data.item.currency) }}</span>
      </template>
      <template #cell(startAt)="data">
        <span>{{ getTimeframe(data.item.startAt, data.item.endAt) }}</span>
      </template>
      <template #cell(uuid4)="data">
        <b-btn-group text="">
          <ButtonSet :isDd="isXs" :useCols="false" :id="items.indexOf(data.item)" :item="data.item" suffix="invTab"
              :beforeShow="true" beforeVariant="primary" @beforeClick="getPdf(data.value)" beforeSuffix="Dl" beforeIcon="dl">
            <template #before>
              {{ $t('tra.ibtnl') }}
            </template>
          </ButtonSet>
        </b-btn-group>
      </template>
    </b-table>
    <b-row fluid v-if="items.length">
      <b-col v-if="showPagination" class="d-flex align-items-end">
        <Pagination ref="pagi" @click="changePage" />
      </b-col>
      <b-col>
        <slot name="nextToPagi" />
      </b-col>
    </b-row>
    <b-card v-if="!items.length">
      <b-link v-if="noHitLink" :to="noHitLink">{{ noHitText }}</b-link>
      <span v-else>{{ noHitText }}</span>
    </b-card>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { BIconReceipt, BIconCardList } from 'bootstrap-vue';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import Pagination from '@/components/Pagination';
import ButtonSet from '@/components/transactions/ButtonSet';

export default {
  data() {
    return {
      sortBy: 'startAt',
      sortDesc: false,
      // items and pagination would need to be moved to store to survive a "back" from showDetails
      items: [],
    }
  },
  created() {
    // values would need to be moved to store to survive "F5"
    [this.sortDesc, this.sortBy, ] = Query.fetchSort(this.query, this.sortDesc, this.sortBy);
    Query.setSort(this.query, this.sortDesc, this.sortBy, 'uuid4');
    this.getInvData();
  },
  computed: {
    fields() {
      return [
        { key: 'type', label: '', class: 'pl-2 pr-1 pl-sm-2 pr-sm-1 pl-md-3', thStyle: 'width: 25px;' },
        { key: 'number', label: this.$t('tra.tab.name'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 40%;', sortable: true },
        { key: 'amount', label: this.$t('tab.u'), class: 'px-1 px-sm-3', thStyle: 'width: 25%;', sortable: true },
        { key: 'startAt', label: this.$t('tra.tab.time'), class: 'px-1 px-sm-3', thStyle: 'width: 35%;', sortable: true },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
  },
  methods: {
    getMoney(a, c) {
      if (a == null) return '';
      return this.$n(a/100, { key: 'c', currency: c });
    },
    // getTextFormat(item) {
    //   return item.receiverName === null ? 'font-italic' : 'font-weight-normal';
    // },
    onConfirmation() {
      // no pagination change necessary: state change does not affect order, delete will auto-update, add does not happen
      this.getInvData(); // update full table, maybe just delete one line better
    },
    sortChanged(ctx) {
      if (ctx.sortBy == '') { return; }
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      Query.setSort(this.query, ctx.sortDesc, ctx.sortBy, 'uuid4');
      if (this.showPagination) {
        this.$refs.pagi.reset();
      }
      Query.setPagi(this.query, null, this.uiSett.visiblePerPage);
      this.getInvData();
    },
    changePage(nextString) {
      Query.setPagi(this.query, nextString, this.uiSett.visiblePerPage);
      this.getInvData();
    },
    getTimeframe(s, e) {
      return this.$t('tra.itimel', {s: this.$d(utcToZonedTime(s+'Z', this.tz), 'do'), e: this.$d(utcToZonedTime(e+'Z', this.tz), 'ds')});
    },
    getInvData() {
      let p = new Promise(resolve => { resolve(REST.get('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid + '/invoices', this.getFormattedQuery())) });
      p.then(
        //   () => { return Promise.reject(); } // FAKE DATA
        // ).catch(
        //   () => {
        //     return Promise.resolve({ d: { data: [{
        //       'uuid4': '11111111-4444-4444-bbbb-123456781234',
        //       'accountUuid4': '4455667788-1234-4321-abcd-123412345555',
        //       'number': 'AU10001',
        //       'amount': null,
        //       'currency': 'EUR',
        //       'createdAt': '2021-02-02 04:20',
        //       'startAt': '2020-12-31 23:00',
        //       'endAt': '2021-01-31 23:00',
        //       'type': 'CLEARING',
        //     },{
        //       'uuid4': '5737cb7d-7349-4d23-92e8-6de5bf9c90d7',
        //       'accountUuid4': '4455667788-1234-4321-abcd-123412345555',
        //       'number': 'RU10001',
        //       'amount': 3599,
        //       'currency': 'EUR',
        //       'createdAt': '2021-02-02 04:21',
        //       'startAt': '2020-12-31 23:00',
        //       'endAt': '2021-01-31 23:00',
        //       'type': 'INVOICE',
        //     }], links: { next: 'cmVzdEY6Njc1NjIwOTM3MjI2NDMyYmEwYTIzYjE4YTNjOTg5OWU=' }}});
        //   }
        ).then(resp => {
          this.items = resp.d.data;
          if (this.showPagination) {
            this.$nextTick(() => { // refs not yet existing (rendered) without nextTick
              if (resp.d.links.next !== '') {
                this.$refs.pagi.update(resp.d.links.next);
              }
              this.$emit('loaded', { hasContent: true, hasMore: this.$refs.pagi.hasMore() });
            });
          }
        }).catch(e => {
          this.items = [];
          // catch 404 warning: user may not have added favs yet
          if (e.code != 404) {
            this.$store.commit('showWarn',e.message);
          }
          this.$emit('loaded', { hasContent: false, hasMore: false });
        })
    },
    getPdf(uuid) {
      REST.get('/invoices/' + uuid, {responseType: 'blob', headers: {Accept: 'application/pdf'}}
        ).then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.d]/*, {type: 'application/pdf'}*/));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', resp.h['content-disposition'].split('=')[1].slice(1, -1)); // relies on specific "Content-Disposition" format
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
        }).catch(e => {
          this.$store.commit('showWarn',e.message);
        });
    },
    getFormattedQuery() {
      // generate "current" query object, based on supplied template and params
      let newQuery = {...this.query};
      return { params: Query.replaceMagic(newQuery) };
    },
    istouch() {
      return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    },
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    noHitText: {
      type: String,
      default: 'No invoices.',
    },
    noHitLink: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: '',
    },
    showShow: {
      type: Boolean,
      default: false,
    },
    showReject: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonSet,
    Pagination,
    Icon,
    BIconReceipt,
    BIconCardList,
  },
}
</script>
