<template>
  <div>
    <InvoiceTable ref="invTable"
        :query="invQuery"
        showShow showDownload showPagination
        :noHitText="$t('tra.ino')" />
  </div>
</template>

<script>
import InvoiceTable from '@/components/transactions/InvoiceTable';

export default {
  data() {
    return {
      invQuery: { s: '-startAt', p: '10' },
    }
  },
  components: {
    // Icon,
    InvoiceTable,
  }
}
</script>
